import { render, staticRenderFns } from "./activity_authorization.vue?vue&type=template&id=052c0964&scoped=true&"
import script from "./activity_authorization.vue?vue&type=script&lang=js&"
export * from "./activity_authorization.vue?vue&type=script&lang=js&"
import style0 from "./activity_authorization.vue?vue&type=style&index=0&id=052c0964&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052c0964",
  null
  
)

export default component.exports